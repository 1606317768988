import React from "react"
import classnames from "classnames"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContributorCard from "../components/ContributorCard"

const Header = ({ children, className = {} }) => (
  <h2
    className={classnames(
      "text-gray-900 text-2xl md:text-3xl font-light my-3",
      className
    )}
  >
    {children}
  </h2>
)

const TextLink = ({ href, children, newWindow = false }) => {
  const newWindowArgs = newWindow
    ? {
        target: "blank",
        rel: "noopener noreferrer",
      }
    : {}

  return (
    <a
      className="underline hover:no-underline text-blue-600"
      href={href}
      {...newWindowArgs}
    >
      {children}
    </a>
  )
}

export default function ContributePage() {
  return (
    <Layout contentClassName="bg-gray-100">
      <SEO title="Contribute to Donate for dharma" />
      <div className="flex flex-col mx-auto container lg:flex-row lg:w-4/5">
        <div className="lg:w-3/5 p-3 lg:p-6">
          <div>
            <Header>About Donate For Dharma</Header>
            <p className="mt-2">
            Donate For Dharma or DFD is a database of dharmik organizations working in India.
            </p>
            <p className="mt-2">
              We help you discover impactful organizations, connect with the
              changemakers behind them, and donate to them. Our data is community-moderated. </p>
              <p className="mt-2">
              DFD is developed by a team of volunteers studying/graduated from  <TextLink newWindow href={"https://www.iitb.ac.in"}>
              IIT Bombay (India)
                </TextLink>. It is a part of community-led website <TextLink
                  newWindow
                  href="https://templesofindia.org">Temples of India</TextLink> project that contains listing of more than 7 lakh temples across India. If
              you&#39;re interested in helping out, apply to join our{" "}
                <TextLink newWindow href={"https://airtable.com/shrq6uTUQeyZAtvmR"}>
                  team.
                </TextLink>
            </p>
            <Header>Submit an organization</Header>
            <p>
              We invite anyone to submit organizations to DFD. The
              only requirement for acceptance is a positive social impact.
            </p>
            <p className="mt-2">
              For submitting organizations providing, use the{" "}
              <TextLink newWindow href={"https://airtable.com/shru2OXtkpN0EkOL0"}>
                Project Form
              </TextLink> 
              .
            </p>
            <Header classname='pt-3'>Community Contributors </Header>
            <p className='mt-2 mb-2'>
            DFD is a community-driven platform. If you'd like to offer your skills and knowledge to help us improve, please fill out the form below. We draw from these applications on a continuous basis and reach out as new opportunities arise. <br/>
            <TextLink newWindow href={"https://airtable.com/shrq6uTUQeyZAtvmR"}>
                  Become a Contributor
            </TextLink>
            </p>
            <p className="mt-2">
              Much of the code is reused from <TextLink href="https://climatescape.org">
                Climatescape
              </TextLink>.
            </p>
            <p className="mt-6">
              Want to help another way?{" "}
              <TextLink href="mailto:ayush.hakmn@gmail.com">
                Get in touch
              </TextLink>
            </p>
          </div>
        </div>
        <div className="p-3 pb-6 lg:p-6 lg:w-2/5">
          <Header>Contributors</Header>
          <div>
            {/* {contributors.map(contributor => (
              <ContributorCard
                key={contributor.name}
                name={contributor.name}
                contributions={contributor.contributions}
                avatar={contributor.avatar}
                website={contributor.website}
              />
            ))} */}

            <ContributorCard
                name='Ayush Maheshwari'
                contributions='Lead'
                avatar={'https://www.cse.iitb.ac.in/~ayusham/pp.jpeg'}
                website='https://www.linkedin.com/in/ayushx'
              />
              <ContributorCard
                name='Arun JayaramaKrishnan'
                contributions='Lead'
                avatar={'https://templesofindia.org/assets/arun.jpeg'}
                website='https://www.linkedin.com/in/arun-jayaramakrishnan-0362a9137/'
              />
              <ContributorCard
                name='Aditya Verma'
                contributions='Developer'
                avatar={'https://res.cloudinary.com/airbnb-clone-dusky/image/upload/c_crop,h_750,w_660/v1636478683/Aditya_Verma_m7a8qa.jpg'}
                website='https://adityaverm-a.vercel.app/'
              />
          </div>
        </div>
      </div>
    </Layout>
  )
}